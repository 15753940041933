import React, { useEffect, useRef } from 'react'
import { Category } from 'ProductListingTypes'
import Product from 'Assets/images/product_img.png'
import { ProductItem as ProductItemType } from 'ProductListingTypes'
import kFormatter from 'Utils/kFormatter'
import { ReactComponent as Redirect } from 'Assets/images/redirect.svg'
import { Empty } from 'antd'
import { handleMTD } from './utils'
import moment from 'moment'

export const SOURCE_OPTIONS = [
  {
    value: 'pcm',
    label: (
      <span>
        Content Source: <span className=" font-semibold">PCM</span>
      </span>
    ),
  },
  {
    value: 'kdb',
    label: (
      <span>
        Content Source: <span className=" font-semibold">KDB</span>
      </span>
    ),
  },
]

export const CATEGORIES: Category[] = [
  {
    title: 'Giày',
    key: 'GIAY',
    children: [
      {
        title: 'Giày Cao Gót',
        key: 'GIAY_CAO_GOT',
        children: [
          {
            title: 'Giày Cao Gót 7cm',
            key: 'GIAY_CAO_GOT_7CM',
            children: [
              {
                title: 'Cate - Level 4',
                key: 'CATE_LEVEL_4',
                children: [
                  {
                    title: 'Cate - Level 5',
                    key: 'CATE_LEVEL_5',
                  },
                ],
              },
            ],
          },
          {
            title: 'Giày Cao Gót 15cm',
            key: 'GIAY_CAO_GOT_15CM',
          },
        ],
      },
      {
        title: 'Giày Sneaker',
        key: 'GIAY_SNEAKER',
      },
    ],
  },
  {
    title: 'Quần',
    key: 'QUAN',
  },
]

export const CUSTOMER_LIST_COLUMNS = [
  { name: 'Ranking', code: 'ranking' },
  { name: 'Barcode', code: 'barcode' },
  { name: 'Product Name', code: 'product_name' },
  { name: 'Image', code: 'image' },
  { name: 'Sold Item', code: 'sold_item' },
  { name: 'Gross Margin', code: 'gross_margin' },
  { name: 'Customers', code: 'customers' },
  { name: 'Returned Customer', code: 'returned_customers' },
  { name: 'Revenue', code: 'revenue' },
  { name: '% Total rev', code: 'ratio_total_revenue' },
  { name: 'DRR', code: 'drr' },
  { name: 'Inventory', code: 'inventory' },
  { name: 'Day to clear', code: 'days_to_clear' },
  { name: 'Pageview', code: 'pageview' },
  { name: 'Unit', code: 'unit' },
]

//const ref = useRef<HTMLDivElement>(null)

export const COLUMNS: any = [
  ...CUSTOMER_LIST_COLUMNS.map((column, index, records) => {
    if (column.name === 'Product Name')
      return {
        title: column.name,
        dataIndex: 'product_name',
        sorter: index == 0 || index == 1 ? true : false,
        width: 200,
        align: 'left',
        render: (name, data) => (
          <div
            //ref={ref}
            className="flex items-center justify-between"
            onMouseEnter={(item) => {
              var test: any = document.getElementsByClassName(
                `ProductListing-redirectIcon-${data.barcode}`
              )
              test[0].style.display = 'block'
            }}
            onMouseLeave={(item) => {
              var test: any = document.getElementsByClassName(
                `ProductListing-redirectIcon-${data.barcode}`
              )
              test[0].style.display = 'none'
            }}
          >
            <span className="cursor-pointer hover:underline truncate">{name}</span>
            <div
              className={`ProductListing-redirectIcon-${data.barcode} cursor-pointer`}
              style={{ display: 'none' }}
            >
              <Redirect
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(data.url, '_blank')
                }}
              ></Redirect>
            </div>
          </div>
        ),
      }
    else if (column.name === 'Image') {
      return {
        title: column.name,
        dataIndex: column.code,
        sorter: index == 0 || index == 1 ? true : false,
        width: 200,
        align: 'left',
        render: (value) =>
          value ? <img src={value} style={{ width: 24, height: 24 }}></img> : <Empty></Empty>,
      }
    } else if (column.name === 'gross_margin') {
      return {
        title: column.name,
        dataIndex: column.code,
        sorter: index == 0 || index == 1 ? true : false,
        width: 200,
        align: 'left',
        render: (value) => <div>{value?.toFixed(0)}%</div>,
      }
    }
    return {
      title: column.name,
      dataIndex: column.code,
      sorter: index == 0 || index == 1 ? true : false,
      width: 200,
      align: 'left',
      render: (value) => <span>{value}</span>,
    }
  }),
]

export const PRODUCT_TAGS = [
  { label: 'Juno', value: 'juno' },
  { label: 'The Coffee House', value: 'tch' },
]

export const BRAND = [
  { label: 'Juno', value: 'juno' },
  { label: 'The Coffee House', value: 'tch' },
]

export const SUPPLIER = [
  { label: 'Juno', value: 'juno' },
  { label: 'The Coffee House', value: 'tch' },
]

export const INITIAL_STATES = {
  filters: {
    dataSource: 'pcm',
    brand: '',
    supplier: '',
    cateId: 'root',
    keyWord: '',
    from_date: handleMTD('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
  },
  pagination: {
    page: 2,
    perPage: 16,
    testPage: 1,
    count: 0,
  },
  loading: {
    initial: false,
    products: true,
    querying: false,
  },
  products: [],
}

export const SAMPLE_ITEM_DATA: ProductItemType[] = [
  {
    id: 1,
    product_name: 'loading',
    ranking: 'loading',
    image: 'loading',
    sold_item: 'loading',
    gross_margin: 'loading',
    customers: 'loading',
    returned_customers: 'loading',
    revenue: 'loading',
    ratio_total_revenue: 'loading',
    drr: 'loading',
    inventory: 'loading',
    days_to_clear: 'loading',
    pageview: 'loading',
    className: 'hide',
  },
  {
    id: 2,
    product_name: 'loading',
    ranking: 'loading',
    image: 'loading',
    sold_item: 'loading',
    gross_margin: 'loading',
    customers: 'loading',
    returned_customers: 'loading',
    revenue: 'loading',
    ratio_total_revenue: 'loading',
    drr: 'loading',
    inventory: 'loading',
    days_to_clear: 'loading',
    pageview: 'loading',
    className: 'hide',
  },
  {
    id: 3,
    product_name: 'loading',
    ranking: 'loading',
    image: 'loading',
    sold_item: 'loading',
    gross_margin: 'loading',
    customers: 'loading',
    returned_customers: 'loading',
    revenue: 'loading',
    ratio_total_revenue: 'loading',
    drr: 'loading',
    inventory: 'loading',
    days_to_clear: 'loading',
    pageview: 'loading',
    className: 'hide',
  },
  {
    id: 4,
    product_name: 'loading',
    ranking: 'loading',
    image: 'loading',
    sold_item: 'loading',
    gross_margin: 'loading',
    customers: 'loading',
    returned_customers: 'loading',
    revenue: 'loading',
    ratio_total_revenue: 'loading',
    drr: 'loading',
    inventory: 'loading',
    days_to_clear: 'loading',
    pageview: 'loading',
    className: 'hide',
  },
  {
    id: 5,
    product_name: 'loading',
    ranking: 'loading',
    image: 'loading',
    sold_item: 'loading',
    gross_margin: 'loading',
    customers: 'loading',
    returned_customers: 'loading',
    revenue: 'loading',
    ratio_total_revenue: 'loading',
    drr: 'loading',
    inventory: 'loading',
    days_to_clear: 'loading',
    pageview: 'loading',
    className: 'hide',
  },
  {
    id: 6,
    product_name: 'loading',
    ranking: 'loading',
    image: 'loading',
    sold_item: 'loading',
    gross_margin: 'loading',
    customers: 'loading',
    returned_customers: 'loading',
    revenue: 'loading',
    ratio_total_revenue: 'loading',
    drr: 'loading',
    inventory: 'loading',
    days_to_clear: 'loading',
    pageview: 'loading',
    className: 'hide',
  },
  {
    id: 7,
    product_name: 'loading',
    ranking: 'loading',
    image: 'loading',
    sold_item: 'loading',
    gross_margin: 'loading',
    customers: 'loading',
    returned_customers: 'loading',
    revenue: 'loading',
    ratio_total_revenue: 'loading',
    drr: 'loading',
    inventory: 'loading',
    days_to_clear: 'loading',
    pageview: 'loading',
    className: 'hide',
  },
  {
    id: 8,
    product_name: 'loading',
    ranking: 'loading',
    image: 'loading',
    sold_item: 'loading',
    gross_margin: 'loading',
    customers: 'loading',
    returned_customers: 'loading',
    revenue: 'loading',
    ratio_total_revenue: 'loading',
    drr: 'loading',
    inventory: 'loading',
    days_to_clear: 'loading',
    pageview: 'loading',
    className: 'hide',
  },
  {
    id: 9,
    product_name: 'loading',
    ranking: 'loading',
    image: 'loading',
    sold_item: 'loading',
    gross_margin: 'loading',
    customers: 'loading',
    returned_customers: 'loading',
    revenue: 'loading',
    ratio_total_revenue: 'loading',
    drr: 'loading',
    inventory: 'loading',
    days_to_clear: 'loading',
    pageview: 'loading',
    className: 'hide',
  },
]
