import moment from 'moment'

export const handleMTD = (format: string) => {
  const arrDate = format.split('-')
  return `${arrDate[0]}-${arrDate[1]}-01`
}

export const createOrderBy = (field: string | null, order: string | null) => {
  if (!field || !order) return ''
  return order === 'ascend' ?  `-${field}` : field
}