import { useState, useCallback } from 'react'
import { message } from 'antd'
import { getProductDetailList } from 'Services/Analytics/product-analytics'
import { ProductItem } from 'ProductListingTypes'

interface FetchProductsParams {
  category_id: string
  source: string
  page: number
  per_page: number
  limit: number
  brands: string
  suppliers: string
  from_date: string
  to_date: string
  keyword: string
  order_by: string
}

interface ProductState {
  productList: ProductItem[]
  totalCount: number
  currentPage: number
  isLoading: boolean
}

export const useProductsFetch = () => {
  const [productState, setProductState] = useState<ProductState>({
    productList: [],
    totalCount: 0,
    currentPage: 1,
    isLoading: false
  })

  const fetchProducts = useCallback(async (params: FetchProductsParams) => {
    setProductState(prev => ({ ...prev, isLoading: true }))
    try {
      const response = await getProductDetailList(params)
      return {
        products: response.data.results,
        total: response.data.count
      }
    } catch (error) {
      message.error('Failed to fetch products')
      return { products: [], total: 0 }
    } finally {
      setProductState(prev => ({ ...prev, isLoading: false }))
    }
  }, [])

  const initProductList = useCallback(async (params: FetchProductsParams) => {
    const { products, total } = await fetchProducts({ ...params, page: 1 })
    setProductState(prev => ({
      ...prev,
      productList: products,
      totalCount: total,
      currentPage: 1
    }))
  }, [fetchProducts])

  const loadMore = useCallback(async (params: FetchProductsParams) => {
    if (productState.isLoading) return

    const { products } = await fetchProducts({
      ...params,
      page: productState.currentPage + 1
    })
    
    setProductState(prev => ({
      ...prev,
      productList: [...prev.productList, ...products],
      currentPage: prev.currentPage + 1
    }))
  }, [fetchProducts, productState.currentPage, productState.isLoading])

  const fetchPage = useCallback(async (params: FetchProductsParams) => {
    const { products, total } = await fetchProducts(params)
    setProductState(prev => ({
      ...prev,
      productList: products,
      totalCount: total,
      currentPage: params.page
    }))
  }, [fetchProducts])

  return {
    ...productState,
    initProductList,
    loadMore,
    fetchPage
  }
}