import React, { useEffect, useState } from 'react'
import { Form, Input, message, Radio, Select } from 'antd'
import { useStoreState, useStoreActions } from 'easy-peasy'

import FormItem from '../FormItem'
import axios from 'Utils/cj-axios'
import OnelifeAppPushSchema, { AI_SCHEMAS } from './constants'

export default function AppPushChanel() {
  const { content } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const [model, setModel] = useState<string>(content?.mode || 'dynamic')
  const [form] = Form.useForm()
  const [aiChecks, setAiChecks] = useState<
    { name: string; code: string; options?: { name: string; code: string }[]; params?: string[] }[]
  >([])
  const [products, setProducts] = useState<any>([])
  const [productsWithBlogs, setProductsWithBlogs] = useState<any>([])
  const [productDetail, setProductDetail] = useState<any>({})
  const [openSelectAi, setOpenSelectAi] = useState(false)
  const fetchProductDetail = async (id: string) => {
    const resp = await axios.get(`/customer-journeys/ai-generate-noti/${id}`)
    setProductDetail(resp.data)
    setState({ key: 'content', value: { ...content, productPreview: resp.data } })
    if (model === 'dynamic') {
      OnelifeAppPushSchema?.forEach((schema: any) => {
        if (['title', 'content', 'image', 'large_image', 'cta'].includes(schema?.name)) {
          const detailField =
            schema?.name === 'title'
              ? 'headline'
              : schema?.name === 'content'
              ? 'body'
              : schema?.name
          form.setFieldsValue({
            [schema.detailField]:
              schema?.name === 'cta'
                ? [
                    {
                      option: 'primary',
                      sub_option: '',
                      deep_link: resp?.data?.deeplink_value,
                      title: resp?.data?.cta,
                    },
                  ]
                : resp?.data?.[detailField],
          })
        }
      })
      setState({
        key: 'content',
        value: {
          ...content,
          title: resp?.data?.headline || content?.title,
          content: resp?.data?.body || content?.content,
          image: resp?.data?.image || content?.image,
          large_image: resp?.data?.large_image || content?.large_image,
          cta:
            [
              {
                option: 'primary',
                sub_option: '',
                deep_link: resp?.data?.deeplink_value,
                title: resp?.data?.cta,
              },
            ] || content?.cta,
        },
      })
    }
  }
  const fetchBlogDetail = async (id: string) => {
    const resp = await axios.get(`/customer-journeys/ai-generate-recipe/${id}`)
    setProductDetail(resp.data)
    setState({ key: 'content', value: { ...content, productPreview: resp.data } })
    if (model === 'dynamic') {
      OnelifeAppPushSchema?.forEach((schema: any) => {
        if (['title', 'content', 'image', 'large_image', 'cta'].includes(schema?.name)) {
          const detailField =
            schema?.name === 'title'
              ? 'headline'
              : schema?.name === 'content'
              ? 'body'
              : schema?.name
          form.setFieldsValue({
            [schema.detailField]:
              schema?.name === 'cta'
                ? [
                    {
                      option: 'primary',
                      sub_option: '',
                      deep_link: resp?.data?.deeplink_value,
                      title: resp?.data?.cta,
                    },
                  ]
                : resp?.data?.[detailField],
          })
        }
      })
      setState({
        key: 'content',
        value: {
          ...content,
          title: resp?.data?.headline || content?.title,
          content: resp?.data?.body || content?.content,
          image: resp?.data?.image || content?.image,
          large_image: resp?.data?.large_image || content?.large_image,
          cta:
            [
              {
                option: 'primary',
                sub_option: '',
                deep_link: resp?.data?.deeplink_value,
                title: resp?.data?.cta,
              },
            ] || content?.cta,
        },
      })
    }
  }
  useEffect(() => {
    setState({ key: 'isContentValidated', value: false })
    setState({
      key: 'content',
      value: {
        ...content,
        mode: content?.mode || 'dynamic',
        utm_source: OnelifeAppPushSchema?.find((schema) => schema?.name === 'utm_source')
          ?.defaultValue,
        utm_medium: OnelifeAppPushSchema?.find((schema) => schema?.name === 'utm_medium')
          ?.defaultValue,
      },
    })
    const fetchAiChecks = async () => {
      const resp = await axios.get('/customer-journeys/ai-suggestions')
      setAiChecks(resp.data)
    }

    const fetchProducts = async () => {
      const resp = await axios.get('/customer-journeys/ai-products')
      setProducts(resp.data)
    }
    const fetchProductsWithBlogs = async () => {
      const resp = await axios.get('/customer-journeys/ai-products?group=gen_recipe')
      setProductsWithBlogs(resp.data)
    }
    fetchProducts()
    fetchProductsWithBlogs()
    fetchAiChecks()
  }, [])

  const onFinish = () => {
    message.success('Content is validated')
    setState({ key: 'isContentValidated', value: true })
    if (!content?.mode) {
      setState({ key: 'content', value: { ...content, mode: 'dynamic' } })
    }
  }

  const isRecommendRecipeBlog = content?.type === 'recommendRecipeBlog'
  const productList = isRecommendRecipeBlog ? productsWithBlogs : products

  // Map options with product_variant_id as value and product_variant_code in data
  const options = productList.map((item: any) => ({
    label: item?.product_name,
    value: item?.product_variant_id, // Use id as value
    code: item?.product_variant_barcode, // Additional data stored here
  }))

  // Handle change event
  const handleChange = (id: string) => {
    // Find the selected option by id
    const selectedOption = options.find((option) => option.value === id)
    if (!selectedOption) return

    console.log({ selectedOption })

    // Use product_variant_code for further actions
    if (isRecommendRecipeBlog) {
      fetchBlogDetail(selectedOption.code)
    } else {
      fetchProductDetail(selectedOption.code)
    }
  }

  const renderBody = () => {
    return (
      <Form
        layout="vertical"
        name="ContentForm"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        requiredMark
        onFinish={onFinish}
        initialValues={content}
        scrollToFirstError
        form={form}
      >
        <div>
          <div className="mb-2 font-semibold">Type</div>
          <Radio.Group
            onChange={(e) => {
              setModel(e.target.value)
              form.setFieldsValue({ mode: e.target.value })
              setState({ key: 'isContentValidated', value: false })
              setState({ key: 'content', value: { ...content, mode: e.target.value } })
            }}
            value={model}
            className="mb-4"
          >
            <Radio value={'dynamic'}>Dynamic</Radio>
            <Radio value={'generate'}>AI generated</Radio>
          </Radio.Group>
        </div>
        <div className="mb-4">
          <div className=" font-semibold">AI Suggestion Type</div>
        </div>
        <Select
          className=" cursor-pointer w-full"
          options={aiChecks?.map((ai) => ({
            label: ai?.name,
            value: ai?.code,
          }))}
          value={content?.type || content?.subType}
          open={openSelectAi}
          onDropdownVisibleChange={(visible) => {
            setOpenSelectAi(visible)
          }}
          dropdownRender={() => {
            return (
              <div className="w-full gap-3 rounded">
                {aiChecks.map((ai) => {
                  if (ai?.options?.length) {
                    return (
                      <div className="pb-0" key={ai?.code}>
                        <div>
                          <div className="h-10 flex items-center pl-4">{ai.name}</div>
                          <div className="flex gap-2 flex-col cursor-pointer">
                            {ai?.options?.map((option) => (
                              <div
                                className=" cursor-pointer h-10 flex items-center hover:bg-gray-100 pb-0 pl-8 text-gray-500"
                                key={option?.code}
                                onClick={() => {
                                  form.setFieldsValue({
                                    type: ai?.code,
                                    params: ai?.params,
                                    subType: option?.code,
                                  })
                                  setState({ key: 'isContentValidated', value: false })
                                  setState({
                                    key: 'content',
                                    value: {
                                      ...content,
                                      type: ai?.code,
                                      params: ai?.params,
                                      subType: option?.code,
                                    },
                                  })
                                  setOpenSelectAi(false)
                                }}
                              >
                                {option?.name}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div
                      className=" cursor-pointer h-10 flex items-center hover:bg-gray-100 pl-4"
                      key={ai?.code}
                      onClick={() => {
                        form.setFieldsValue({ type: ai?.code, params: ai?.params })
                        setState({ key: 'isContentValidated', value: false })
                        setState({
                          key: 'content',
                          value: { ...content, type: ai?.code, params: ai?.params },
                        })
                        setOpenSelectAi(false)
                      }}
                    >
                      {ai.name}
                    </div>
                  )
                })}
              </div>
            )
          }}
          placeholder="Choose AI Suggestion Type"
        ></Select>
        <div className="mb-4"></div>
        <div>
          <div>
            <div className="mb-2 font-semibold">Product item</div>
            <Select
              className="mb-2 w-full rounded"
              placeholder="Select a product"
              onChange={handleChange}
              showSearch
              onSearch={() => {}}
              filterOption={(input, option) =>
                ((option?.label as any) ?? '')?.toLowerCase().includes(input.toLowerCase())
              }
              options={options}
            />
            <div className=" font-normal mb-4">
              Chọn từ danh sách sản phẩm để xem được content tương ứng{' '}
            </div>
          </div>
        </div>
        {model === 'generate' ? (
          <div className=" flex flex-col gap-3">
            <div>
              <div className=" font-semibold mb-2">Title</div>
              <Input value={productDetail?.headline} disabled className=" px-4 py-2"></Input>
            </div>
            <div>
              <div className=" font-semibold mb-2">Content</div>
              <Input value={productDetail?.body} disabled className=" px-4 py-2"></Input>
            </div>
            <div>
              <div className=" font-semibold mb-2">Image</div>
              <img src={productDetail?.image} width={210} height={92} alt=""></img>
            </div>
            <div>
              <div className=" font-semibold mb-2">Large Image</div>
              <img src={productDetail?.large_image} width={210} height={92} alt=""></img>
            </div>
            <div>
              <div className=" font-semibold mb-2">Button label</div>
              <Input value={productDetail?.cta} disabled className=" px-4 py-2"></Input>
            </div>
            <div>
              <div className=" font-semibold mb-2">Navigate to</div>
              <Input value={productDetail?.deeplink_value} disabled className=" px-4 py-2"></Input>
            </div>
            {AI_SCHEMAS?.map((schema: any) => (
              <Form.Item
                key={schema.name}
                label={schema.label}
                tooltip={schema.tooltip}
                className={schema.className || ''}
                name={schema.name}
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues[schema.name] !== currentValues[schema.name]) return true
                  return false
                }}
                rules={[
                  ...(schema.isRequired
                    ? [
                        {
                          required: schema.isRequired,
                          message: `${schema.label} is required`,
                        },
                      ]
                    : []),
                  ...(schema.validatePattern
                    ? [
                        {
                          pattern: schema.validatePattern,
                          message: `${schema.label} doesn't match the pattern`,
                        },
                      ]
                    : []),
                ]}
                initialValue={schema.defaultValue}
              >
                <FormItem
                  name={schema?.name}
                  type={schema?.type}
                  value={
                    productDetail
                      ? form.getFieldValue(
                          productDetail[
                            schema?.name === 'title'
                              ? 'headline'
                              : schema?.name === 'content'
                              ? 'body'
                              : schema?.name
                          ]
                        )
                      : form.getFieldValue(content && content[schema?.name])
                  }
                  options={schema.options}
                  placeholder={schema.placeholder || ''}
                  onChange={(name, value) => {
                    form.setFieldsValue({ [name]: value })
                    setState({ key: 'isContentValidated', value: false })
                    setState({ key: 'content', value: { ...content, [name]: value } })
                  }}
                />
              </Form.Item>
            ))}
          </div>
        ) : (
          <>
            {OnelifeAppPushSchema?.map((schema: any) => (
              <Form.Item
                key={schema.name}
                label={schema.label}
                tooltip={schema.tooltip}
                className={schema.className || ''}
                name={schema.name}
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues[schema.name] !== currentValues[schema.name]) return true
                  return false
                }}
                rules={[
                  ...(schema.isRequired
                    ? [
                        {
                          required: schema.isRequired,
                          message: `${schema.label} is required`,
                        },
                      ]
                    : []),
                  ...(schema.validatePattern
                    ? [
                        {
                          pattern: schema.validatePattern,
                          message: `${schema.label} doesn't match the pattern`,
                        },
                      ]
                    : []),
                ]}
                initialValue={schema.defaultValue}
              >
                <FormItem
                  name={schema?.name}
                  type={schema?.type}
                  value={form.getFieldValue(content && content[schema?.name])}
                  options={schema.options}
                  placeholder={schema.placeholder || ''}
                  onChange={(name, value) => {
                    form.setFieldsValue({ [name]: value })
                    setState({ key: 'isContentValidated', value: false })
                    setState({ key: 'content', value: { ...content, [name]: value } })
                  }}
                  dynamicParams={content?.params}
                />
              </Form.Item>
            ))}
          </>
        )}
      </Form>
    )
  }
  return <div>{renderBody()}</div>
}
