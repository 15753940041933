import { useState, useCallback } from 'react'
import { message } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { getBrandFilterList, getProductListingCategoryList } from 'Services/Analytics/product-analytics'
import { Category } from 'ProductListingTypes'
import { debounce } from 'lodash'

interface FilterState {
  dataSource: string
  view: string
  brand: string
  supplier: string
  keyWord: string
  from_date: string
  to_date: string
  sortField: string
  sortOrder: string
  cateId: string
}

interface FilterData {
  brandFilterList: { label: string; value: string }[]
  supplierFilterList: { label: string; value: string }[]
  categoryList: Category[]
}

export const useFilterData = () => {
  const location = useLocation()
  const history = useHistory()
  
  const [filterData, setFilterData] = useState<FilterData>({
    brandFilterList: [],
    supplierFilterList: [],
    categoryList: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  
  const getFiltersFromURL = useCallback((): FilterState => ({
    dataSource: queryString.parse(location.search).dataSource?.toString() || 'pcm',
    view: queryString.parse(location.search).view?.toString() || 'grid',
    brand: queryString.parse(location.search).brand?.toString() || '',
    supplier: queryString.parse(location.search).supplier?.toString() || '',
    keyWord: queryString.parse(location.search).keyword?.toString() || '',
    from_date: queryString.parse(location.search).from_date?.toString() || '',
    to_date: queryString.parse(location.search).to_date?.toString() || '',
    sortField: queryString.parse(location.search).sortField?.toString() || '',
    sortOrder: queryString.parse(location.search).sortOrder?.toString() || '',
    cateId: queryString.parse(location.search).cateId?.toString() || 'root',
  }), [location.search])

  const debouncedUpdateURL = useCallback(
    debounce((filters: FilterState) => {
      const currentParams = queryString.parse(location.search)
      
      const searchParams = queryString.stringify({
        ...currentParams,
        ...filters
      })
      
      history.replace({
        pathname: location.pathname,
        search: searchParams
      })
    }, 300),
    [history, location]
  )

  const updateURLParams = useCallback((filters: FilterState) => {
    debouncedUpdateURL(filters)
  }, [debouncedUpdateURL])

  const fetchFilterData = useCallback(async (dataSource: string) => {
    setIsLoading(true)
    try {
      const [brandResp, categoryResp] = await Promise.all([
        getBrandFilterList(),
        getProductListingCategoryList({ source: dataSource })
      ])

      const brandData = brandResp.data
        ?.filter(i => i?.name === 'brands')?.[0]
        .data.map(item => ({
          label: item.name,
          value: item.id,
        }))

      const supplierData = brandResp.data
        ?.filter(i => i?.name === 'suppliers')?.[0]
        .data.map(item => ({
          label: item.name,
          value: item.id,
        }))

      setFilterData({
        brandFilterList: brandData,
        supplierFilterList: supplierData,
        categoryList: categoryResp?.data?.children || []
      })
    } catch (error) {
      message.error('Failed to fetch filter data')
    } finally {
      setIsLoading(false)
    }
  }, [])

  return {
    ...filterData,
    isLoading,
    fetchFilterData,
    getFiltersFromURL,
    updateURLParams
  }
}